@font-face {
  font-family: 'JetBrainsMono';
  src: url('./fonts/JetBrainsMono-Regular.ttf') format('truetype');
  /* Замените путь и формат шрифта в соответствии с вашими файлами */
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'JetBrainsMono', monospace;
  /* Используйте шрифт в вашем приложении */
}
body {
  margin: 0;
  font-family: 'JetBrainsMono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
