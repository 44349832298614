.menu {
  padding: 10px;
  width: 35vw;
  height: calc(100vh - 62px);
}

.block-logo {
  height: 20vh;
  min-height: 120px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  background-color: #e9ecef;
  border-radius: 5px;
}

.tasks-block {
  margin-top: 20px;
  border: 1px solid black;
  background-color: #e9ecef;
  border-radius: 5px;
  height: calc(80vh - 60px);
  max-height: calc(100vh - 180px);
  overflow: auto;
}

.logo {
  max-width: 100%;
  max-height: 100%;
}

.menu-button {
  margin: 5px;
  border-radius: 10px;
  border: 1px solid black;
  width: 90px;
  &:hover {
    cursor: pointer;
    background-color: black;
    color: white
  };
}

@media only screen and (max-width: 767px) {
  .menu {
    width: calc(100vw - 20px);
    padding: 0px
  }
  .tasks-block {
    margin-top: 10px;
    height: calc(80vh - 30px);
  }
    .block-logo {
      margin-bottom: 10px;
    }
}
