:root {
  --border-radius: 10px;
  --block-width: calc(100% - 10px)

}

.block-with-task {
  margin: 10px;
  padding: 10px;
  height: calc(100vh - 40px);
  width: calc(65vw - 40px);
  border: 1px solid black;
  background-color: #e9ecef;
  border-radius: 5px;
  overflow: auto;
}

.header-task {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}

.send-form {
  padding: 10px;
  margin: 5px;
  border: 1px solid black;
  background-color: white;
  border-radius: var(--border-radius);
  width: var(--block-width);
  min-height: 110px;
  height: 15%;
  align-items: center;
}

.send-form-button {
  color: white;
  border-radius: var(--border-radius);
  border: 1px solid black;
  width: 100%;
  height: 30px;
  text-align: center;
  margin: 5px;
  background-color: black;
  &:hover {
    cursor: pointer;
    background-color: white !important;
    color: black
  };
}

.check-button {
  color: white;
  background-color: black;
  border-radius: var(--border-radius);
  border: 1px solid black;
  width: var(--block-width);
  height: 30px;
  text-align: center;
  margin: 5px;
  &:hover {
    cursor: pointer;
    background-color: white;
    color: black
  };
}

.double-buttons {
  width: var(--block-width);
  display: flex;
  justify-content: space-between;
  margin: 5px;
}

.black-button {
  border-radius: var(--border-radius);
  border: 1px solid black;
  background-color: black;
  color: white;
  width: 50%;
  height: 40px;
  &:hover {
    cursor: pointer;
    background-color: white;
    color: black
  };
}

.white-button {
  border-radius: var(--border-radius);
  border: 1px solid black;
  background-color: white;
  width: 50%;
  min-height: 40px;
  &:hover {
    cursor: pointer;
    background-color: black;
    color: white
  };
}

.send-form-badge {
  font-size: 10px;
  color: green
}

.data-json {
  padding: 5px;
  margin: 5px;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid black;
  width: var(--block-width)
}

.form-with-input {
  height: 30px;
  display: flex;
  justify-content: space-between;
}

.input-in-form {
  height: 30px;
  border-radius: var(--border-radius);
  border: 1px solid black;
  width: calc(70% - 10px);
  margin: 5px
}

.result-task {
  padding: 5px;
  margin: 5px;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid black;
  width: var(--block-width);
}

.need-help-block {
  padding: 5px;
  margin: 5px;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid black;
}

.need-help-button {
  margin: 5px;
  color: grey;
  &:hover {
    cursor: pointer;
    color: black
  };
}

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked+.custom-radio-black {
  background-color: black;
}

input[type="radio"]:checked+.custom-radio-green {
  background-color: green;
}

input[type="radio"]:checked+.custom-radio-red {
  background-color: red;
}

.custom-radio-black {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid black;
  border-radius: 50%;
  margin-right: 5px;
}

.custom-radio-green {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid green;
  border-radius: 50%;
  margin-right: 5px;
}

.custom-radio-red {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid red;
  border-radius: 50%;
  margin-right: 5px;
}

.result-block {
  margin: 5px;
  background-color: white;
  padding: 5px;
  border-radius: var(--border-radius);
  width: var(--block-width);
  border: 1px solid black;
}

@media only screen and (max-width: 767px) {
  .block-with-task {
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
    margin: 0px;
  }
  .send-form {
    width: calc(100% - 0px);
    margin: 0px;
    margin-left: 0px;
  }
  .check-button {
    width: calc(100% - 0px);
    margin-right: 0px;
    margin-left: 0px;
  }
  .double-buttons {
    width: calc(100% - 0px);
    margin-right: 0px;
    margin-left: 0px;
  }
  .data-json {
    width: calc(100% - 0px);
    margin-right: 0px;
    margin-left: 0px;
  }
  .result-task {
    width: calc(100% - 0px);  
    margin-right: 0px;
    margin-left: 0px;
  }
  .result-block {
    width: calc(100% - 0px);
    margin-right: 0px;
    margin-left: 0px;
  }
}
