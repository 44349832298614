.main-page {
  display: flex;
  flex-direction: row;
  padding: 10px;
  user-select: none;
}

.question-list {
  flex: 1;
}

.work-area {
  flex: 2;
}

@media only screen and (max-width: 767px) {
  .question-list {
    display: none;
  }

  .question-list.hidden {
    display: block;
  }

  .main-page.mobile-menu-open .work-area {
    display: none;
  }
}
