.block-with-tasks {
  display: flex;
  align-content: center;
  flex-direction: column;
  padding: 10px
}

.task {
  padding: 5px;
  margin: 1px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
    background-color: rgba(39, 40, 44, .4);
  };

}

.task.selected {
  background-color: rgba(39, 40, 44, .4);
}

.lock-icon {
  font-size: 16px;
}

.popover {
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  width: 300px
}

.green {
  background-color: green;
  border-radius: 3px;
  padding: 1px;
}

.blue {
  background-color: rgb(46, 136, 180);
  border-radius: 3px;
  padding: 1px;
}

.purple {
  background-color: rgba(190, 58, 213, 0.954);
  border-radius: 3px;
  padding: 1px;
}

.tasks-list-number {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 3px;
  width: 25px;
  color: black;
}
