.submit-button {
  color: white !important;
  background-color: black !important;
  border-radius: 10px !important;
  border: 1px solid black!important;
  height: 30px;
  display: flex !important;
  text-align: center;
  &:hover {
    cursor: pointer;
    background-color: white !important;
    color: black !important
  };
}
