.dropdown-for-lang {
  position: absolute;
  top: 0;
  right: 150%;
  background-color: white;
  border: 1px solid white;
  padding: 8px;
  z-index: 1000;
  font-size: 14px;
  cursor: pointer;
  border-radius: var(--border-radius);
}

.popover-buttons {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 10px 0px;
}

.popover-button {
  width: 100px;
  border: 1px solid black;
  border-radius: 10px;
  font-size: 17px;

  &:hover {
    cursor: pointer;
    background-color: black;
    color: white
  }

  ;
}
